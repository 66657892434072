import { BorderColor, DeleteForever, GridViewOutlined } from '@mui/icons-material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import TableActionButton from '../../../components/buttons/TableActionButton'
import DeckItems from '../../../components/deck-items/DeckItems'
import { SelectOption } from '../../../constants/type-constants'
import { useCommonService } from '../../../hooks/useCommonService'

interface VesselTableProps {
    vesselTableRows: any[]
    handleEditClick: (id: any) => Promise<void>
    handleDeleteClick: (id: any) => Promise<void>
    handleLayoutClick: (id: any) => Promise<void>
}
const VesselTable: React.FC<VesselTableProps> = ({ vesselTableRows, handleEditClick, handleDeleteClick, handleLayoutClick }) => {
    const {
        fetchDeckOptions
    } = useCommonService()

    const [deckOptions, setDeckOptions] = useState<SelectOption[]>([]);

    useEffect(() => {
        fetchDeckOptions(setDeckOptions);
    }, []);

    const vesselTableColumns: GridColDef[] = [
        { field: 'slNo', headerName: 'Sl No' },
        { field: 'vesselName', headerName: 'Name', flex: 2 },
        { field: 'vesselRegNo', headerName: 'Reg No', flex: 2 },
        { field: 'vesselType', headerName: 'Type', flex: 1 },
        { field: 'baseGhat', headerName: 'Base Ghat', flex: 2 },
        { field: 'tripDays', headerName: 'Trip Days', flex: 3 },
        { field: 'owner', headerName: 'Owner', flex: 2 },
        {
            align: 'center',
            flex: 2,
            minWidth: 150,
            headerAlign: 'center',
            field: 'capacity',
            headerName: 'Capacity',
            sortable: false,
            filterable: false,
            renderCell: (params: any) => {
                const { deckItems } = params.row;
                return (<DeckItems items={deckItems} />);
            },
        },
        {
            align: 'center',
            flex: 2,
            minWidth: 150,
            headerAlign: 'center',
            field: 'action',
            headerName: 'Action',
            sortable: false,
            filterable: false,
            renderCell: (params: any) => {
                const { id } = params.row;
                return (
                    <div className='flex gap-2 justify-center items-center h-full'>
                        <TableActionButton
                            type='primary'
                            onClick={async () => handleEditClick(id)}>
                            <BorderColor fontSize='small' />
                        </TableActionButton>
                        <TableActionButton
                            type='danger'
                            onClick={async () => handleDeleteClick(id)}>
                            <DeleteForever fontSize='small' />
                        </TableActionButton>
                        <TableActionButton
                            type='primary'
                            onClick={async () => handleLayoutClick(id)}>
                            <GridViewOutlined fontSize='small' />
                        </TableActionButton>
                    </div>
                );
            },
        }
    ]

    return (
        <div className='mt-3'>
            <div className='flex justify-center'>
                <div className='flex w-full bg-white'>
                    <DataGrid
                        rows={vesselTableRows}
                        columns={vesselTableColumns}
                        autosizeOnMount
                        autoHeight
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 10 },
                            },
                        }}
                        pageSizeOptions={[5, 10, 15]}
                    />
                </div>
            </div>
        </div>
    )
}

export default VesselTable
import apiUrl from "../constants/api-constants";
import { useApiService } from "./useApiService";
import { useHandleErrorService } from "./useHandleErrorService";
import { AxiosResponse } from "axios";
import { SelectOption } from "../constants/type-constants";
import { Division } from "../pages/manage/division-management/division-interfaces";
import { Ghat } from "../pages/manage/ghat-management/ghat-interfaces";
import { SelectedDaysMap, TripDay, Vessel } from "../pages/manage/vessel-management/vessel-interfaces";
import { ItemType } from "../pages/manage/vessel-layout/vessel-layout-interfaces";
import { Route } from "../pages/manage/route-management/route-interfaces";
import toast from "react-hot-toast";
// import { ItemType } from "../pages/manage/vessel-matrix/vessel-layout-interfaces";

export const useCommonService = () => {
    const { fetchPublicRequest } = useApiService();
    const { handleError } = useHandleErrorService();

    // VESSEL TYPE OPTIONS FROM ENUM (e.g., Ferry, Ropax, etc/)
    const fetchVesselTypeOptions = async (
        updateState: React.Dispatch<React.SetStateAction<SelectOption[]>>,
        setOption?: React.Dispatch<React.SetStateAction<string>>
    ) => {
        try {
            const response: AxiosResponse = await fetchPublicRequest(
                apiUrl.common.enums.vesselTypes
            );
            const { object: vesselTypes } = response.data;
            const options: SelectOption[] = [];
            vesselTypes.forEach((item: string) => {
                options.push({
                    value: item,
                    label: item
                })
            });

            updateState(options);
            if (setOption) {
                setOption(options[0].value);
            }
        } catch (error) {
            handleError(error);
        }
    }

    const fetchUnitTypes = async () => {
        try {
            const response: AxiosResponse = await fetchPublicRequest(
                apiUrl.common.enums.unitTypes
            );

            if (response) {
                return response;
            }
            return null;
        } catch (error) {
            handleError(error);
        }
    }

    // STATUS OPTIONS FROM ENUM (e.g., Active, Inactive, Deleted, etc.)
    const fetchStatusOptions = async (
        updateState: React.Dispatch<React.SetStateAction<SelectOption[]>>,
        setOption?: React.Dispatch<React.SetStateAction<string>>
    ) => {
        try {
            const response: AxiosResponse = await fetchPublicRequest(
                apiUrl.common.enums.statuses
            );

            const { object } = response.data;

            const options: SelectOption[] = [];
            object.forEach((item: string) => {
                if (item != 'Deleted') {
                    options.push({
                        value: item,
                        label: item
                    });
                }
            })
            updateState(options);
            if (setOption) {
                setOption(options[0]?.value || '');
            }
        } catch (error) {
            handleError(error);
        }
    }

    // SERVICE TYPE OPTIONS FROM ENUM (e.g., Day, Night, etc)
    const fetchServiceTypeOptions = async (
        updateState: React.Dispatch<React.SetStateAction<SelectOption[]>>,
        setOption?: React.Dispatch<React.SetStateAction<string>>) => {
        try {
            const response: AxiosResponse = await fetchPublicRequest(
                apiUrl.common.enums.serviceTypes
            );
            const { object } = response.data;
            const options: any = [];
            object.forEach((item: string) => {
                options.push({
                    value: item,
                    label: item
                });
            })

            updateState(options);
            if (setOption) {
                setOption(options[0].value);
            }
            return [];
        } catch (error) {
            handleError(error);
        }
    }

    const fetchPwd = async () => {
        try {
            const response: AxiosResponse = await fetchPublicRequest(
                apiUrl.common.enums.pwd
            );

            if (response) {
                const options: any = [];
                response.data.forEach((item: string) => {
                    options.push({
                        value: item,
                        label: item
                    });
                })

                return options;
            }
            return [];
        } catch (error) {
            handleError(error);
        }
    }

    // OWNERS OPTIONS FROM ENUM (e.g., Govt, Private, etc)
    const fetchOwnerOptions = async (
        updateState: React.Dispatch<React.SetStateAction<SelectOption[]>>,
        setOption?: React.Dispatch<React.SetStateAction<string>>
    ) => {
        try {
            const response: AxiosResponse = await fetchPublicRequest(
                apiUrl.common.enums.owners
            );

            const { object: owners } = response.data;
            const options: any = [];
            owners.forEach((item: string) => {
                options.push({
                    value: item,
                    label: item
                });
            })

            updateState(options);
            if (setOption) {
                setOption(options[0].value);
            }
        } catch (error) {
            handleError(error);
        }
    }

    // NIGHT NAVIGATION OPTIONS FROM ENUM (Yes/No) 
    const fetchNightNavigationOptions = async (
        updateState: React.Dispatch<React.SetStateAction<SelectOption[]>>,
        setOption?: React.Dispatch<React.SetStateAction<string>>
    ) => {
        try {
            const response: AxiosResponse = await fetchPublicRequest(
                apiUrl.common.enums.nightNavigations
            );

            const { object: nightNavigations } = response.data;

            const options: any = [];
            nightNavigations.forEach((item: string) => {
                options.push({
                    value: item,
                    label: item
                });
            })

            updateState(options);
            if (setOption) {
                setOption(options[0].value);
            }
        } catch (error) {
            handleError(error);
        }
    }

    const fetchModes = async () => {
        try {
            const response: AxiosResponse = await fetchPublicRequest(
                apiUrl.common.enums.modes
            );

            if (response) {
                const options: any = [];
                response.data.forEach((item: string) => {
                    options.push({
                        value: item,
                        label: item
                    });
                })

                return options;
            }
            return [];
        } catch (error) {
            handleError(error);
        }
    }

    const fetchGenders = async () => {
        try {
            const response: AxiosResponse = await fetchPublicRequest(
                apiUrl.common.enums.genders
            );

            if (response) {
                const options: any = [];
                response.data.forEach((item: string) => {
                    options.push({
                        value: item,
                        label: item
                    });
                })

                return options;
            }
            return [];
        } catch (error) {
            handleError(error);
        }
    }

    const fetchDeviceTypes = async () => {
        try {
            const response: AxiosResponse = await fetchPublicRequest(
                apiUrl.common.enums.deviceTypes
            );

            if (response) {
                const options: any = [];
                response.data.forEach((item: string) => {
                    options.push({
                        value: item,
                        label: item
                    });
                })

                return options;
            }
            return [];
        } catch (error) {
            handleError(error);
        }
    }
    // BOOKING MODE OPTIONS FROM ENUM (Online/Offline) 
    const fetchBookingModeOptions = async (
        updateState: React.Dispatch<React.SetStateAction<SelectOption[]>>,
        setOption?: React.Dispatch<React.SetStateAction<string>>) => {
        try {
            const response: AxiosResponse = await fetchPublicRequest(
                apiUrl.common.enums.bookingModes
            );

            const { object } = response.data;
            const options: SelectOption[] = [];
            object.forEach((item: string) => {
                options.push({
                    value: item,
                    label: item
                });
            })

            updateState(options);
            if (setOption) {
                setOption(options[0].value);
            }

        } catch (error) {
            handleError(error);
        }
    }

    // ================================================================================================
    // ===================================== MASTER ===================================================
    // ================================================================================================

    const fetchWeekDayOptions = async (
        setWeekdays: React.Dispatch<React.SetStateAction<TripDay[]>>,
        setSelectedDays: React.Dispatch<React.SetStateAction<SelectedDaysMap>>
    ) => {
        try {
            const response: AxiosResponse = await fetchPublicRequest(
                apiUrl.common.master.getAllWeekDays
            );

            const { object: weekdays } = response.data;

            const initialSelection: SelectedDaysMap = {};
            weekdays.forEach((day: TripDay) => {
                initialSelection[parseInt(day.id)] = false;
            });

            setWeekdays(weekdays);
            setSelectedDays(initialSelection);

        } catch (error) {
            handleError(error);
        }
    }

    const fetchItemOptions = async (
        updateState: React.Dispatch<React.SetStateAction<SelectOption[]>>,
        setOption?: React.Dispatch<React.SetStateAction<string>>
    ) => {
        try {
            const response: AxiosResponse = await fetchPublicRequest(
                apiUrl.common.master.getAllItems
            );

            const { object: items } = response.data;
            const itemList: SelectOption[] = []
            items.forEach((item: ItemType) => {
                if (item.itemType == "Unreserved") {
                    itemList.unshift({
                        value: item.id,
                        label: item.itemType
                    });
                } else {
                    itemList.push({
                        value: item.id,
                        label: item.itemType
                    });
                }

            });
            updateState(itemList);

            if (setOption) {
                setOption(itemList[0].value);
            }
        } catch (error) {
            handleError(error);
        }
    }

    // DECK OPTIONS FROM ENUM (Deck No 1, Deck No 2, etc)
    const fetchDeckOptions = async (
        updateState: React.Dispatch<React.SetStateAction<SelectOption[]>>,
        setOption?: React.Dispatch<React.SetStateAction<string>>
    ) => {
        try {
            const response: AxiosResponse = await fetchPublicRequest(
                apiUrl.common.master.getAllDecks
            );
            const { object: decks } = response.data;

            const options: SelectOption[] = [];
            decks.forEach((item: any, index: number) => {
                options.push({
                    value: item.id,
                    label: item.deckNo
                });
            })

            updateState(options);
            if (setOption) {
                setOption(options[0].value);
            }
        } catch (error) {
            handleError(error);
        }
    }

    const fetchDivisionOptions = async (
        updateState: React.Dispatch<React.SetStateAction<SelectOption[]>>,
        setOption?: React.Dispatch<React.SetStateAction<string>>
    ) => {
        try {
            const response: AxiosResponse = await fetchPublicRequest(
                apiUrl.common.master.getActiveDivisions
            )
            const { object } = response.data;
            const options: SelectOption[] = [];

            object.forEach((division: Division) => {
                options.push({
                    value: division.id,
                    label: division?.divisionName
                });
            });

            updateState(options);
            if (setOption) {
                setOption(options[0].value);
            }
        } catch (error) {
            handleError(error);
        }
    }

    const fetchGhatOptions = async (
        updateState: React.Dispatch<React.SetStateAction<SelectOption[]>>,
        setOption?: React.Dispatch<React.SetStateAction<string>>
    ) => {
        try {
            const response: AxiosResponse = await fetchPublicRequest(
                apiUrl.common.master.getActiveGhats
            )

            const { object } = response.data;
            const options: SelectOption[] = [];
            object.forEach((ghat: Ghat) => {
                options.push({
                    value: ghat.id,
                    label: ghat.ghatName
                });
            })

            updateState(options);
            if (setOption) {
                setOption(options[0].value);
            }
        } catch (error) {
            handleError(error);
        }
    }

    const fetchGhatOptionsByDivision = async (
        divisionId: string,
        updateState: React.Dispatch<React.SetStateAction<SelectOption[]>>,
        setOption?: React.Dispatch<React.SetStateAction<string>>
    ) => {
        try {
            const response: AxiosResponse = await fetchPublicRequest(
                apiUrl.common.master.getActiveGhatsByDivision,
                { divisionId: divisionId }
            )
            const { object } = response.data;

            const options: SelectOption[] = [];
            object.forEach((ghat: Ghat) => {
                options.push({
                    value: ghat.id,
                    label: ghat.ghatName
                });
            })

            updateState(options);

            if (setOption) {
                setOption(options[0].value);
            }

        } catch (error) {
            handleError(error);
        }
    }

    const fetchRoutesByDivision = async (
        divisionId: string,
        setRoutes: React.Dispatch<React.SetStateAction<Route[]>>
    ) => {
        try {
            const response: AxiosResponse = await fetchPublicRequest(
                apiUrl.common.master.getActiveRoutesByDivision,
                { divisionId: divisionId }
            );
            const { object: routes } = response.data;
            if (routes.length) {
                setRoutes(routes);
                return;
            }
            toast.error("No active routes found under the selected division");
            setRoutes([]);

        } catch (error) {
            handleError(error);
        }
    }

    const fetchVesselOptionsByDivision = async (
        divisionId: string,
        updateState: React.Dispatch<React.SetStateAction<SelectOption[]>>,
        setOption?: React.Dispatch<React.SetStateAction<string>>
    ) => {
        try {
            const response: AxiosResponse = await fetchPublicRequest(
                apiUrl.common.master.getActiveVesselsByDivision,
                { divisionId: divisionId }
            )
            const { object } = response.data;

            const options: SelectOption[] = [];
            object.forEach((vessel: Vessel) => {
                options.push({
                    value: vessel.id,
                    label: vessel.name
                });
            })

            updateState(options);

            if (setOption) {
                setOption(options[0]?.value);
            }

        } catch (error) {
            handleError(error);
        }
    }

    return {
        fetchDivisionOptions,
        fetchGhatOptions,
        fetchGhatOptionsByDivision,
        fetchVesselTypeOptions,
        fetchStatusOptions,
        fetchOwnerOptions,
        fetchNightNavigationOptions,
        fetchWeekDayOptions,
        fetchDeckOptions,
        fetchItemOptions,
        fetchBookingModeOptions,
        fetchServiceTypeOptions,
        fetchRoutesByDivision,
        fetchVesselOptionsByDivision,

        fetchUnitTypes,
        fetchPwd,
        fetchModes,
        fetchGenders,
        fetchDeviceTypes,
    }

}


import React, { useEffect } from "react";
import { useFormErrorContext } from "../../hooks/useFormErrorContext";

interface FormControlProps {
    label?: string;
    addClass?: string[];
    type: string;
    id: string;
    placeholder?: string;
    value?: string | number | boolean;
    onChange?: (e: React.ChangeEvent<any>) => void;
    options?: { value: string; label: string }[];
    mandatoryField?: boolean;
}

const FormControl: React.FC<FormControlProps> = ({
    label,
    addClass = [],
    type,
    id,
    placeholder,
    value,
    onChange,
    options,
    mandatoryField,
}) => {
    const labelClasses = "block mb-2 text-sm font-medium text-gray-900";
    const baseClass = "bg-slate-50 outline outline-1 outline-slate-200 hover:outline-gray-400 text-gray-900 rounded-lg focus:ring-sky-500 focus:outline-sky-500 focus:outline-2 block w-full px-3.5 py-2.5";
    const dateClass = "bg-slate-50 outline outline-1 outline-slate-200 hover:outline-gray-400 text-gray-900 rounded-lg focus:ring-sky-500 focus:outline-sky-500 focus:outline-2 block w-full px-3.5";
    const radioCheckClasses = "w-4 h-4 border border-gray-200 rounded bg-slate-50 focus:ring-3 focus:ring-sky-300";
    const radioCheckLabelClasses = "ml-3 text-sm text-gray-500";

    const additionalClasses = addClass.join(' ');
    const combinedClasses = `${baseClass} ${additionalClasses}`.trim();
    const combinedDateClasses = `${dateClass} ${additionalClasses}`.trim();

    const { formErrors } = useFormErrorContext();

    switch (type) {
        case 'select':
            return (
                <div>
                    {label && <label htmlFor={id} className={labelClasses}>{label} {mandatoryField && <span className="text-red-500">*</span>}</label>}
                    <select
                        id={id}
                        name={id}
                        value={value as string}
                        onChange={onChange}
                        className={combinedClasses}
                        required={mandatoryField}
                    >
                        {options?.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                    {
                        formErrors[id] && formErrors[id] != '' &&
                        <div className="text-red-500 text-xs mt-1 font-medium">{formErrors[id]}</div>
                    }
                </div>
            );
        case 'checkbox':
            return (
                <div className="flex items-center">
                    <input
                        id={id}
                        name={id}
                        type="checkbox"
                        checked={value as boolean}
                        onChange={onChange}
                        className={`${radioCheckClasses} ${additionalClasses}`}
                    />
                    {label && <label htmlFor={id} className={radioCheckLabelClasses}>{label}</label>}
                    {
                        formErrors[id] && formErrors[id] != '' &&
                        <div className="text-red-500 text-xs mt-1 font-medium">{formErrors[id]}</div>
                    }
                </div>
            );
        case 'radio':
            return (
                <div className="flex items-center">
                    <input
                        id={id}
                        name={id}
                        type="radio"
                        value={value as string}
                        onChange={onChange}
                        className={`${radioCheckClasses} ${additionalClasses}`}
                        required={mandatoryField}
                    />
                    {label && <label htmlFor={id} className={radioCheckLabelClasses}>{label}</label>}
                    {
                        formErrors[id] && formErrors[id] != '' &&
                        <div className="text-red-500 text-xs mt-1 font-medium">{formErrors[id]}</div>
                    }
                </div>
            );
        case 'textarea':
            return (
                <div>
                    {label && <label htmlFor={id} className={labelClasses}>{label}</label>}
                    <textarea
                        id={id}
                        name={id}
                        value={value as string}
                        onChange={onChange}
                        rows={3}
                        placeholder={placeholder}
                        className={combinedClasses}
                        required={mandatoryField}
                    />
                    {
                        formErrors[id] && formErrors[id] != '' &&
                        <div className="text-red-500 text-xs mt-1 font-medium">{formErrors[id]}</div>
                    }
                </div>
            );
        case 'date':
            // For date
            return (
                <div>
                    {label && <label htmlFor={id} className={labelClasses}>{label} {mandatoryField && <span className="text-red-500">*</span>}</label>}
                    <input
                        type={type}
                        id={id}
                        name={id}
                        value={value as string}
                        placeholder={placeholder}
                        onChange={onChange as React.ChangeEventHandler<HTMLInputElement>}
                        className={combinedDateClasses}
                        style={{
                            paddingTop: '0.47rem',
                            paddingBottom: '0.47rem'
                        }}
                        required={mandatoryField}
                    />
                    {
                        formErrors[id] && formErrors[id] != '' &&
                        <div className="text-red-500 text-xs mt-1 font-medium">{formErrors[id]}</div>
                    }
                </div>
            );
        default:
            // For email, password, number, and other input types
            return (
                <div>
                    {label && <label htmlFor={id} className={labelClasses}>{label} {mandatoryField && <span className="text-red-500">*</span>}</label>}
                    <input
                        type={type}
                        id={id}
                        name={id}
                        value={value as string}
                        placeholder={placeholder}
                        onChange={onChange as React.ChangeEventHandler<HTMLInputElement>}
                        className={combinedClasses}
                        style={{
                            paddingTop: '0.52rem',
                            paddingBottom: '0.52rem'
                        }}
                        required={mandatoryField}
                    />
                    {
                        formErrors[id] && formErrors[id] != '' &&
                        <div className="text-red-500 text-xs mt-1 font-medium">{formErrors[id]}</div>
                    }
                </div>
            );
    }
}

export default FormControl;

import { AxiosResponse } from "axios";
import toast from "react-hot-toast";
import apiUrl from "../../../constants/api-constants";
import { SelectOption } from "../../../constants/type-constants";
import { useApiService } from "../../../hooks/useApiService";
import { useHandleErrorService } from "../../../hooks/useHandleErrorService";
import { Route } from "../route-management/route-interfaces";
import { TripSettingsPost, TripSettingsTableRow, TripSettingsView } from "./trip-settings-interfaces";

export const useTripSettingsService = () => {

    const {
        fetchAuthRequest,
        postAuthRequest
    } = useApiService();
    const { handleError } = useHandleErrorService();

    const handleInputChange = (
        updateState: React.Dispatch<React.SetStateAction<string>>
    ) => (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        updateState(e.target.value);
    }

    const generateGhatFromOptions = (
        routes: Route[],
        setGhatFromOptions: React.Dispatch<React.SetStateAction<SelectOption[]>>,
        setGhatFrom: React.Dispatch<React.SetStateAction<string>>,
    ) => {
        const ghats: SelectOption[] = [];
        routes.forEach(route => {
            if (!ghats.find(ghat => ghat.value == route.ghatFrom.id)) {
                ghats.push({
                    value: route.ghatFrom.id,
                    label: route.ghatFrom.ghatName
                })
            }
        });
        setGhatFromOptions(ghats);
        setGhatFrom(ghats[0]?.value);
    }

    const generateGhatToOptions = (
        routes: Route[],
        ghatFrom: string,
        setGhatToOptions: React.Dispatch<React.SetStateAction<SelectOption[]>>,
        setGhatTo: React.Dispatch<React.SetStateAction<string>>,
    ) => {
        const ghats: SelectOption[] = [];
        routes.forEach(route => {
            if (!ghats.find(ghat => ghat.value == route.ghatTo.id)) {
                if (route.ghatFrom.id == ghatFrom) {
                    ghats.push({
                        value: route.ghatTo.id,
                        label: route.ghatTo.ghatName
                    })
                }
            }
        });
        setGhatToOptions(ghats);
        setGhatTo(ghats[0]?.value);
    }

    // ==============================================================
    // ------------------- FETCH TRIP SETTINGS  ---------------------
    // ==============================================================
    const fetchTripSettings = async (
        routes: Route[],
        ghatFrom: string,
        ghatTo: string,
        setTripSettingsTableRows: React.Dispatch<React.SetStateAction<TripSettingsTableRow[]>>,
        setTripList: React.Dispatch<React.SetStateAction<TripSettingsView[]>>
    ) => {
        try {
            const routeData = routes.find(route => route.ghatFrom.id == ghatFrom && route.ghatTo.id == ghatTo);
            if (routeData) {
                const response: AxiosResponse = await fetchAuthRequest(
                    apiUrl.manage.tripSettings.getTripSettingsByRoute,
                    {
                        routeId: routeData.id
                    }
                );

                const { object } = response.data;
                const rows: TripSettingsTableRow[] = [];
                object.forEach((trip: TripSettingsView, index: number) => {

                    rows.push({
                        slNo: index + 1,
                        id: trip.id,
                        route: `${trip.route.ghatFrom.ghatName} to ${trip.route.ghatTo.ghatName}`,
                        departureTime: trip.departureTime,
                        serviceType: trip.serviceType,
                        tripDuration: trip.tripDuration,
                        dockNo: trip.dockNo,
                        vessel: trip.vessel.name,
                        status: trip.status,
                        deckItems: trip.vessel.vesselDeckItems,
                    })
                });

                setTripSettingsTableRows(rows);
                setTripList(object);
            }
        } catch (error) {
            handleError(error);
        }
    }

    // ==============================================================
    // -------------------- SAVE TRIP SETTINGS  ---------------------
    // ==============================================================
    const saveTripSettings = async (
        postData: TripSettingsPost,
    ) => {
        try {
            const response: AxiosResponse = await postAuthRequest(
                apiUrl.manage.tripSettings.saveTripSettings,
                postData
            );
            const { message } = response.data;
            toast.success(message);

            return true;
        } catch (error) {
            handleError(error);
            return false;
        }
    }
    return {
        handleInputChange,
        generateGhatFromOptions,
        generateGhatToOptions,
        fetchTripSettings,
        saveTripSettings,
    }
} 
import React, { useEffect, useState } from 'react'
import { ModalAction, Passenger, Vehicle } from '../typeDefs'
import ModalWrapper from '../../../../components/modals/ModalWrapper'
import DefaultButton from '../../../../components/buttons/DefaultButton'
import FormControl from '../../../../components/forms/FormControl'
import { SelectOption } from '../../../../constants/type-constants'
import toast from 'react-hot-toast'

interface VehicleDetailsProps {
    trip: any
    vehicleList: Vehicle[]
    setVehicleList: React.Dispatch<React.SetStateAction<Vehicle[]>>
    callAction: ModalAction
    setCallAction: React.Dispatch<React.SetStateAction<ModalAction>>
    passengerList: Passenger[]
}

const VehicleDetails: React.FC<VehicleDetailsProps> = ({
    trip,
    vehicleList,
    setVehicleList,
    callAction,
    setCallAction,
    passengerList,
}) => {

    const [vehicleId, setVehicleId] = useState('');
    const [vehicleType, setVehicleType] = useState('');
    const [regNo, setRegNo] = useState('');
    const [model, setModel] = useState('');
    const [owner, setOwner] = useState('');
    const [ownerName, setOwnerName] = useState('');
    const [passengerOptions, setPassengerOptions] = useState<SelectOption[]>([]);

    useEffect(() => {
        if (callAction.action == 'add') {
            setVehicleType(callAction.vehicleType || '');
            setVehicleId('');
            setModel('');
            setRegNo('');
            setOwnerName(passengerList.find(passenger => passenger.id == passengerOptions[0]?.value)?.passengerName || '');
            setOwner(passengerOptions[0]?.value);
            handleVehicleDetailsModalOpen();
        }

        if (callAction.action == 'edit') {
            const vehicle = vehicleList.find(vehicle => vehicle.id == callAction.id);
            if (vehicle) {
                setPassengerOptions([{
                    value: vehicle.passengerUniqueId,
                    label: vehicle.ownerName,
                }, ...passengerOptions]);

                setVehicleId(vehicle.id)
                setVehicleType(vehicle.vehicleType);
                setModel(vehicle.model);
                setRegNo(vehicle.regNo);
                setOwnerName(passengerList.find(passenger => passenger.id == vehicle.passengerUniqueId)?.passengerName || '');
                setOwner(vehicle.passengerUniqueId);
                handleVehicleDetailsModalOpen();
            } else {
                toast.error('Invalid Vehicle Details');
            }
        }
    }, [callAction]);

    useEffect(() => {
        const options: any = [];
        passengerList.forEach((passenger) => {
            if (!vehicleList.find(vehicle => vehicle.passengerUniqueId == passenger.id)) {
                options.push({
                    value: passenger.id,
                    label: passenger.passengerName,
                });
            }
        });
        setPassengerOptions(options);
    }, [passengerList, vehicleList]);

    const [vehicleDetailsModalOpen, setVehicleDetailsModalOpen] = useState(false);
    const handleVehicleDetailsModalOpen = () => setVehicleDetailsModalOpen(true);
    const handleVehicleDetailsModalClose = () => {
        setCallAction({
            action: '',
            vehicleType: '',
        });
        setVehicleDetailsModalOpen(false)
    };

    const handleVehicleInputChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
        const value = e.target.value;
        switch (e.target.name) {
            case 'model':
                setModel(value);
                break;
            case 'regNo':
                setRegNo(value);
                break;
            // case 'vehicleType':
            //     setVehicleType(value);
            //     break;
            case 'owner':
                // setOwnerName(passengerList.find(passenger => passenger.id == value)?.passengerName);
                setOwner(value);
                break;
            default:
                break;
        }
    }

    const handleSaveVehicleDetailsClick = async () => {
        const deckItem = trip.tripDeckItems.find((deckItem: any) => deckItem.item.itemCategory == 'Vehicle' && deckItem.item.itemType == vehicleType)
        const vehicle = {
            id: vehicleId ? vehicleId : (vehicleList.length + 1).toString(),
            model: model,
            regNo: regNo,
            item: {
                id: '2',
                itemType: 'Two Wheeler'
            },
            vehicleType: vehicleType,
            passengerUniqueId: owner.toString(),
            ownerName: ownerName,
        }
        const vehicleIndex = vehicleList.findIndex(v => v.id == vehicle.id);
        if (vehicleIndex != -1) {
            const updatedVehicleList = [...vehicleList];
            updatedVehicleList[vehicleIndex] = vehicle;
            setVehicleList(updatedVehicleList);
            toast.success('Vehicle Details Updated');
        } else {
            setVehicleList([...vehicleList, vehicle]);
            toast.success('Vehicle Added');
        }
        handleVehicleDetailsModalClose();
    }

    return (
        <ModalWrapper
            open={vehicleDetailsModalOpen}
            handleClose={handleVehicleDetailsModalClose}
            title={'Vehicle Details'} >
            <h6 className='text-sky-500 text-center mb-4'>Vehicle Type: {vehicleType}</h6>
            <form id='vehicleForm' className='flex flex-wrap w-full'>
                <div className='w-full mb-3 lg:w-1/3 lg:pr-4'>
                    <input type="text" value={vehicleId} readOnly hidden />
                    <FormControl
                        label='Vehicle Model'
                        type={'text'}
                        id={'model'}
                        value={model}
                        onChange={handleVehicleInputChange}
                        mandatoryField={true}
                    />
                </div>
                <div className='w-full mb-3 lg:w-1/3 lg:pr-3'>
                    <FormControl
                        label='Registration No'
                        type={'text'}
                        id={'regNo'}
                        value={regNo}
                        onChange={handleVehicleInputChange}
                        mandatoryField={true}
                    />
                </div>
                <div className='w-full mb-3 lg:w-1/3'>
                    <FormControl
                        label='Owner'
                        type={'select'}
                        id={'owner'}
                        value={owner}
                        options={passengerOptions}
                        onChange={handleVehicleInputChange}
                        mandatoryField={true}
                    />
                </div>
                <div className='flex justify-center w-full mt-3'>
                    <DefaultButton
                        onClick={handleSaveVehicleDetailsClick}
                        buttonText={"Save Vehicle Details"}
                    />
                </div>
            </form>
        </ModalWrapper >
    )
}

export default VehicleDetails
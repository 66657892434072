import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import toast from 'react-hot-toast'
import Body from '../../components/body/Body'
import DefaultButton from '../../components/buttons/DefaultButton'
import Card from '../../components/cards/Card'
import FormControl from '../../components/forms/FormControl'
import apiUrl from '../../constants/api-constants'
import { ROUTES } from '../../constants/routes-constants'
import { useApiService } from '../../hooks/useApiService'
import { useHandleErrorService } from '../../hooks/useHandleErrorService'

const SignUp: React.FC = () => {
    const { postPublicRequest } = useApiService();
    const { handleError } = useHandleErrorService();

    const navigate = useNavigate();
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [mobileNo, setMobileNo] = useState('');

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        switch (e.target.name) {
            case 'fullName':
                setFullName(e.target.value);
                break;
            case 'email':
                setEmail(e.target.value);
                break;
            case 'mobileNo':
                setMobileNo(e.target.value);
                break;
            default:
                break;
        }
    }

    const handleClick = async () => {
        const postData = {
            fullName: fullName,
            mobileNo: mobileNo,
            email: email,
        }

        try {
            const response: any = await postPublicRequest(apiUrl.auth.postSendOtp, postData);
            if (response) {
                const { message } = response.data;

                toast.success(message);
                setTimeout(() => {
                    Cookies.set('fullName', fullName, { secure: true });
                    Cookies.set('email', email, { secure: true });
                    Cookies.set('mobileNo', mobileNo, { secure: true });
                    navigate(ROUTES.AUTH.VERIFY_OTP);
                }, 1500);
            }
        } catch (error: any) {
            handleError(error);
        }
    }

    return (
        <Body>
            <div className="w-full md:w-7/12 lg:w-5/12 flex justify-center m-auto">
                <Card>
                    <div>
                        <h1 className="text-sky-600 text-xl font-medium text-center leading-tight tracking-tight md:text-2xl">
                            Sign Up
                        </h1>
                        <form className="space-y-2 md:space-y-3" id="signUpForm">
                            <FormControl
                                label={'Name'}
                                type={'text'}
                                id={'fullName'}
                                value={fullName}
                                onChange={handleInputChange}
                                mandatoryField={true}
                            />
                            <FormControl
                                label={'Phone'}
                                type={'text'}
                                id={'mobileNo'}
                                value={mobileNo}
                                onChange={handleInputChange}
                                mandatoryField={true}
                            />
                            <FormControl
                                label={'Email'}
                                type={'email'}
                                id={'email'}
                                value={email}
                                onChange={handleInputChange}
                                mandatoryField={false}
                            />
                            <br />
                            <DefaultButton
                                onClick={handleClick}
                                buttonText="Get OTP"
                                disabledTime={0}
                            />

                            <p className="text-sm font-medium text-gray-500">
                                Already registered? <button onClick={() => navigate(ROUTES.AUTH.LOGIN)} className="font-semibold text-sky-600 hover:underline">Login</button>
                            </p>
                        </form>
                    </div>
                </Card>
            </div>

        </Body >
    )
}

export default SignUp
import axios from 'axios';
import apiUrl from '../constants/api-constants';
import { useLoading } from '../contexts/LoadingContext';
import useAuth from './useAuth';
import { useTokenRefresh } from './useTokenRefresh';
import { useLogout } from './useLogout';
import { store } from '../store/reducers/store';
import { useFormErrorContext } from './useFormErrorContext';

const useAxiosInterceptor = () => {
    const { refreshToken } = useTokenRefresh();
    const { startLoading, stopLoading } = useLoading();
    const { clearFormErrors } = useFormErrorContext();
    const axiosInstance = axios.create({
        baseURL: apiUrl.BASE_URL,
    });

    let isRefreshing = false;
    let failedQueue: Array<any> = [];
    let requestCount = 0;  // Track the number of active requests

    const processQueue = (error: any, token: string | null = null) => {
        failedQueue.forEach((prom: any) => {
            if (token) {
                prom.resolve(token);
            } else {
                prom.reject(error);
            }
        });
        failedQueue = [];
    };

    // Function to increment the request counter
    const addRequest = () => {
        if (requestCount === 0) {
            startLoading();
        }
        requestCount++;
    };

    // Function to decrement the request counter and stop the loader when no requests are left
    const removeRequest = () => {
        requestCount--;
        if (requestCount === 0) {
            stopLoading();
        }
    };

    // Request interceptor to add the authorization header
    axiosInstance.interceptors.request.use(
        (config) => {
            clearFormErrors();
            if (!config.url?.includes('/auth/refreshtoken')) {
                addRequest();
            }
            return config;
        },
        (error) => {
            removeRequest();
            return Promise.reject(error);
        }
    );

    // Response interceptor to handle token refresh
    axiosInstance.interceptors.response.use(
        (response) => {
            removeRequest();
            return response;
        },
        async (error) => {
            const { response, config } = error;
            const originalRequest = config;
            const { data } = store.getState();
            
            if (response && data.isAuthenticated && response.data.errorType === "INVALID_SESSION") {
                if (!isRefreshing) {
                    isRefreshing = true;
                    try {
                        const { accessToken, tokenType } = await refreshToken();

                        // Process the queued requests with the new token
                        processQueue(null, `${tokenType} ${accessToken}`);
                        isRefreshing = false;

                        // Retry the original request with the new token
                        originalRequest.headers['Authorization'] = `${tokenType} ${accessToken}`;
                        const result = await axiosInstance(originalRequest);

                        removeRequest();
                        return result;

                    } catch (refreshError) {
                        processQueue(refreshError, null);
                        isRefreshing = false;
                        removeRequest();
                        return Promise.reject(refreshError);
                    }
                }

                // If a token refresh is already happening, queue the requests until it's done
                return new Promise((resolve, reject) => {
                    failedQueue.push({ resolve, reject });
                }).then((token) => {
                    originalRequest.headers['Authorization'] = token;
                    return axiosInstance(originalRequest);
                }).catch((err) => {
                    removeRequest();
                    return Promise.reject(err);
                });
            }

            removeRequest();
            return Promise.reject(error);
        }
    );

    return axiosInstance;
};

export default useAxiosInterceptor;

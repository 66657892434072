import AddRoundedIcon from '@mui/icons-material/AddRounded';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import MapIcon from '@mui/icons-material/Map';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React, { ChangeEvent, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import Body from '../../../components/body/Body';
import DefaultButton from '../../../components/buttons/DefaultButton';
import TableActionButton from '../../../components/buttons/TableActionButton';
import Card from '../../../components/cards/Card';
import FormControl from '../../../components/forms/FormControl';
import ModalWrapper from '../../../components/modals/ModalWrapper';
import apiUrl from '../../../constants/api-constants';
import { useApiService } from '../../../hooks/useApiService';
import { useCommonService } from '../../../hooks/useCommonService';
import { useHandleErrorService } from '../../../hooks/useHandleErrorService';
import { useGhatManagementService } from './useGhatManagementService';
import { SelectOption } from '../../../constants/type-constants';
import GhatTable from './GhatTable';
import { GhatTableRows } from './ghat-interfaces';

const GhatManagement: React.FC = () => {
    const {
        fetchStatusOptions,
        fetchDivisionOptions,
    } = useCommonService();

    const { handleError } = useHandleErrorService();

    const {
        handleInputChange,
        fetchGhatsByDivision,
        saveGhat,
        deleteGhat,
    } = useGhatManagementService();

    const [ghatTableRows, setGhatTableRows] = useState<GhatTableRows[]>([]);
    const [divisionOptions, setDivisionOptions] = useState<SelectOption[]>([]);
    const [ghatId, setGhatId] = useState('');

    const [ghatName, setGhatName] = useState('');
    const [division, setDivision] = useState('');
    const [status, setStatus] = useState('');
    const [statusOptions, setStatusOptions] = useState<SelectOption[]>([]);

    const [ghatModalOpen, setGhatModalOpen] = useState(false);

    const handleGhatModalOpen = () => setGhatModalOpen(true);
    const handleGhatModalClose = () => setGhatModalOpen(false);

    useEffect(() => {
        fetchDivisionOptions(setDivisionOptions, setDivision);
        fetchStatusOptions(setStatusOptions, setStatus);
    }, []);

    // EDIT CLICK HANDLER
    const handleTableButtonEditClick = async (id: string, ghatName: string, division: string, status: string) => {
        setGhatId(id.toString());
        setGhatName(ghatName);
        setDivision(division);
        setStatus(status);
        handleGhatModalOpen();
    };

    // DELETE CLICK HANDLER
    const handleTableButtonDeleteClick = async (id: string, ghatName: string, division: string) => {
        const postData = {
            id: id,
            ghatName: ghatName,
            division: {
                id: division,
                divisionName: divisionOptions.find(option => option.value == division)?.label || ''
            },
            status: statusOptions.find(status => status.value == 'Deleted')?.value || 'Deleted'
        };
        const deleted = await deleteGhat(postData);
        if (deleted) {
            fetchGhatsByDivision(division, setGhatTableRows);
            handleGhatModalClose();
        }
    };

    const handleAddButtonClick = async () => {
        setGhatId('');
        setGhatName('');
        setDivision(divisionOptions[0].value);
        setStatus(statusOptions[0].value);
        handleGhatModalOpen();
    };

    const handleFetchGhatsClick = async () => fetchGhatsByDivision(division, setGhatTableRows);

    const handleGhatSaveClick = async () => {
        try {
            const postData = {
                id: ghatId == '' ? null : ghatId,
                ghatName: ghatName,
                division: {
                    id: division,
                    divisionName: divisionOptions.find(option => option.value == division)?.label || ''
                },
                status: status
            };
            const saved = await saveGhat(postData)
            if (saved) {
                fetchGhatsByDivision(division, setGhatTableRows);
                handleGhatModalClose();
            }
        } catch (error) {
            handleError(error);
        }
    }

    return (
        <Body>
            <div className='flex flex-wrap justify-between items-end'>
                <h3 className='flex justify-center items-center gap-4 mb-2'><MapIcon /> Ghat Management</h3>
            </div>
            <Card>
                <div className='flex w-full items-end justify-between gap-2'>
                    <div className='flex items-end gap-2'>
                        <div>
                            <FormControl
                                label='Select Division'
                                type={'select'}
                                id={'division'}
                                value={division}
                                onChange={handleInputChange(setDivision)}
                                options={divisionOptions}
                            />
                        </div>
                        <div>
                            <DefaultButton
                                buttonText='Fetch Ghats'
                                onClick={handleFetchGhatsClick}
                                disabledTime={1}
                            />
                        </div>
                    </div>
                    <div className='flex w-max'>
                        <DefaultButton
                            onClick={handleAddButtonClick}
                            buttonText='Add New Ghat'
                            disabledTime={1}
                            buttonIcon={AddRoundedIcon}
                        />
                    </div>
                </div>
            </Card>

            <GhatTable
                rows={ghatTableRows}
                handleEditClick={handleTableButtonEditClick}
                handleDeleteClick={handleTableButtonDeleteClick}
            />

            <ModalWrapper
                open={ghatModalOpen}
                handleClose={handleGhatModalClose}
                title={'Ghat Details'}
            >
                <form id='ghatForm' className='flex flex-wrap'>
                    <div className='w-full lg:w-2/4 lg:pr-4'>
                        <input type='text' id='id' name='id' value={ghatId} readOnly hidden />
                        <FormControl
                            label='Ghat Name'
                            type={'text'}
                            id={'ghatName'}
                            value={ghatName}
                            onChange={handleInputChange(setGhatName)}
                            mandatoryField={true}
                        />
                    </div>
                    <div className='w-full lg:w-1/4 lg:pr-4'>
                        <input type='text' id='id' name='id' value={ghatId} readOnly hidden />
                        <FormControl
                            label='Division'
                            type={'select'}
                            id={'division'}
                            value={division}
                            options={divisionOptions}
                            onChange={handleInputChange(setDivision)}
                            mandatoryField={true}
                        />
                    </div>
                    <div className='w-full lg:w-1/4'>
                        <input type='text' id='id' name='id' value={ghatId} readOnly hidden />
                        <FormControl
                            label='Status'
                            type={'select'}
                            id={'status'}
                            value={status}
                            options={statusOptions}
                            onChange={handleInputChange(setStatus)}
                            mandatoryField={true}
                        />
                    </div>
                </form>
                <div className='mt-4'>
                    <DefaultButton
                        onClick={handleGhatSaveClick}
                        buttonText={'Save Ghat Details'}
                    />
                </div>
            </ModalWrapper>
        </Body>
    )
}

export default GhatManagement
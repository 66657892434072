import React, { useEffect, useState } from 'react'
import FormControl from '../../../../components/forms/FormControl'
import ModalWrapper from '../../../../components/modals/ModalWrapper'
import DefaultButton from '../../../../components/buttons/DefaultButton'
import { validationService } from '../../../../services/validationService'
import { useTicketBookingService } from '../useTicketBookingService'
import { SelectOption } from '../../../../constants/type-constants'
import { ModalAction, Passenger } from '../typeDefs'
import toast from 'react-hot-toast'
import { dateUtil } from '../../../../utility/dateUtil'

interface PassengerDetailsProps {
    passengerList: Passenger[]
    setPassengerList: React.Dispatch<React.SetStateAction<Passenger[]>>
    callAction: ModalAction
    setCallAction: React.Dispatch<React.SetStateAction<ModalAction>>
}

const PassengerDetails: React.FC<PassengerDetailsProps> = ({ passengerList, setPassengerList, callAction, setCallAction }) => {

    const {
        fetchGenderOptions,
        fetchPwdOptions,
    } = useTicketBookingService();

    const [genderOptions, setGenderOptions] = useState<SelectOption[]>([]);
    const [pwdOptions, setPwdOptions] = useState<SelectOption[]>([]);

    // const [passengerList, setPassengerList] = useState<any[]>([]);
    const [passengerId, setPassengerId] = useState('');
    const [passengerName, setPassengerName] = useState('');
    const [mobileNo, setMobileNo] = useState('');
    const [email, setEmail] = useState('');
    const [pwd, setPwd] = useState('');
    const [gender, setGender] = useState('');
    const [age, setAge] = useState('');
    const [passengerOptions, setPassengerOptions] = useState<any[]>([]);

    useEffect(() => {
        fetchGenderOptions(setGenderOptions, setGender);
        fetchPwdOptions(setPwdOptions, setPwd);
    }, []);

    useEffect(() => {
        if (callAction.action == 'add') {
            setPassengerId('');
            setPassengerName('');
            setMobileNo('');
            setEmail('');
            setPwd(pwdOptions[0]?.value);
            setGender(genderOptions[0]?.value);
            setAge('');
            handlePassengerDetailsModalOpen();
        }

        if (callAction.action == 'edit') {
            const passenger = passengerList.find(passenger => passenger.id == callAction.id);
            if (passenger) {
                setPassengerId(passenger.id);
                setPassengerName(passenger.passengerName);
                setMobileNo(passenger.mobileNo);
                setEmail(passenger.email);
                setPwd(passenger.pwd);
                setGender(passenger.gender);
                setAge(passenger.age.toString());
                handlePassengerDetailsModalOpen();
            } else {
                toast.error('Invalid Passenger Details')
            }
        }
    }, [callAction]);

    const [passengerDetailsModalOpen, setPassengerDetailsModalOpen] = useState(false);
    const handlePassengerDetailsModalOpen = () => setPassengerDetailsModalOpen(true);
    const handlePassengerDetailsModalClose = () => {
        setCallAction({ action: '' });
        setPassengerDetailsModalOpen(false)
    };

    const handlePassengerInputChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
        const value = e.target.value;
        switch (e.target.name) {
            case 'passengerName':
                setPassengerName(value);
                break;
            case 'mobileNo':
                if (validationService.isValidMobileInput(value)) {
                    setMobileNo(value);
                }
                break;
            case 'email':
                setEmail(value);
                break;
            case 'age':
                setAge(value);
                break;
            case 'gender':
                setGender(value);
                break;
            case 'pwd':
                setPwd(value);
                break;
            default:
                break;
        }
    }

    const handleSavePassengerDetailsClick = async () => {
        const id = passengerId ? passengerId : dateUtil.getTimestamp();
            const passenger: Passenger = {
                id: id.toString(),
                passengerName: passengerName,
                mobileNo: mobileNo,
                email: email,
                pwd: pwd,
                gender: gender,
                age: Number(age),
                uniqueId: id.toString()
            }
            const passengerIndex = passengerList.findIndex(p => p.id == passengerId);
            if (passengerIndex != -1) {
                const updatedPassengerList = [...passengerList];
                updatedPassengerList[passengerIndex] = passenger;
                setPassengerList(updatedPassengerList);
                toast.success('Passenger Details Updated');
            } else {
                setPassengerList([...passengerList, passenger]);
                toast.success('Passenger Added');
            }

            handlePassengerDetailsModalClose();
    }

    return (
        <ModalWrapper
            open={passengerDetailsModalOpen}
            handleClose={handlePassengerDetailsModalClose}
            title={'Passenger Details'} >
            <form id="passengerForm" className='flex flex-wrap w-full'>
                <div className='w-full mb-3 lg:w-2/4 lg:pr-4'>
                    <input type="text" value={passengerId} readOnly hidden />
                    <FormControl
                        label='Name'
                        type={'text'}
                        id={'passengerName'}
                        value={passengerName}
                        onChange={handlePassengerInputChange}
                        mandatoryField={true}
                    />
                </div>
                <div className='w-full mb-3 lg:w-1/4 lg:pr-3'>
                    <FormControl
                        label='Age'
                        type={'number'}
                        id={'age'}
                        value={age}
                        onChange={handlePassengerInputChange}
                        mandatoryField={true}
                    />
                </div>
                <div className='w-full mb-3 lg:w-1/4'>
                    <FormControl
                        type={'select'}
                        id={'gender'}
                        label='Gender'
                        options={genderOptions}
                        value={gender}
                        onChange={handlePassengerInputChange}
                        mandatoryField={true}
                    />
                </div>
                <div className='w-full mb-3 lg:w-1/3 lg:pr-3'>
                    <FormControl
                        label='Mobile'
                        type={'text'}
                        id={'mobileNo'}
                        value={mobileNo}
                        onChange={handlePassengerInputChange}
                    />
                </div>
                <div className='w-full mb-3 lg:w-1/3 lg:pr-3'>
                    <FormControl
                        label='Email'
                        type={'email'}
                        id={'email'}
                        value={email}
                        onChange={handlePassengerInputChange}
                    />
                </div>
                <div className='w-full mb-3 lg:w-1/3'>
                    <FormControl
                        type={'select'}
                        id={'pwd'}
                        label='Differently Abled?'
                        options={pwdOptions}
                        value={pwd}
                        onChange={handlePassengerInputChange}
                        mandatoryField={true}
                    />
                </div>
                <div className='flex justify-center w-full mt-3'>
                    <DefaultButton
                        onClick={handleSavePassengerDetailsClick}
                        buttonText={"Save Passenger Details"}
                    />
                </div>
            </form>
        </ModalWrapper>
    )
}

export default PassengerDetails
import { AxiosResponse } from "axios";
import apiUrl from "../../../constants/api-constants";
import { SelectOption } from "../../../constants/type-constants";
import { useApiService } from "../../../hooks/useApiService";
import { Ghat } from "../ghat-management/ghat-interfaces";
import { Route } from "../route-management/route-interfaces";
import { Trip, TripManagementTableRow, TripPost } from "./trip-interfaces";
import { useHandleErrorService } from "../../../hooks/useHandleErrorService";
import toast from "react-hot-toast";

export const useTripManagementService = () => {

    const {
        fetchAuthRequest,
        postAuthRequest
    } = useApiService();
    const { handleError } = useHandleErrorService();

    const handleInputChange = (updateState: React.Dispatch<React.SetStateAction<string>>) =>
        (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
            const val = e.target.value;
            updateState(val);
        }

    const generateGhatFromOptions = (
        routes: Route[],
        setGhatFromOptions: React.Dispatch<React.SetStateAction<SelectOption[]>>,
        setGhatFrom: React.Dispatch<React.SetStateAction<string>>,
    ) => {
        const ghats: SelectOption[] = [];
        routes.forEach((route: Route) => {
            if (!ghats.find((ghat: SelectOption) => ghat.value == route.ghatFrom.id)) {
                ghats.push({
                    value: route.ghatFrom.id,
                    label: route.ghatFrom.ghatName
                })
            }
        });
        setGhatFromOptions(ghats);
        setGhatFrom(ghats[0]?.value);
    }

    const generateGhatToOptions = (
        routes: Route[],
        ghatFrom: string,
        setGhatToOptions: React.Dispatch<React.SetStateAction<SelectOption[]>>,
        setGhatTo: React.Dispatch<React.SetStateAction<string>>,
    ) => {
        const ghats: SelectOption[] = [];
        routes.forEach((route: Route) => {
            if (!ghats.find((ghat: SelectOption) => ghat.value == route.ghatTo.id)) {
                if (route.ghatFrom.id == ghatFrom) {
                    ghats.push({
                        value: route.ghatTo.id,
                        label: route.ghatTo.ghatName
                    })
                }
            }
        });
        setGhatToOptions(ghats);
        setGhatTo(ghats[0]?.value);
    }




    // ==============================================================
    // ----------------------- FETCH TRIPS --------------------------
    // ==============================================================
    const fetchTrips = async (
        routes: Route[],
        ghatFrom: string,
        ghatTo: string,
        tripDate: string,
        setTripTableRows: React.Dispatch<React.SetStateAction<TripManagementTableRow[]>>,
        setTripList: React.Dispatch<React.SetStateAction<any[]>>
    ) => {
        try {
            const route = routes.find(route => route.ghatFrom.id == ghatFrom && route.ghatTo.id == ghatTo);
            if (route) {
                const response: AxiosResponse = await fetchAuthRequest(
                    apiUrl.manage.trip.getTrips,
                    {
                        routeId: route.id,
                        tripDate: tripDate
                    }
                );

                if (response) {
                    const { object: trips } = response.data;

                    const rows: TripManagementTableRow[] = [];
                    trips.forEach((trip: Trip, index: number) => {
                        rows.push({
                            slNo: index + 1,
                            id: trip.id,
                            route: `${trip.route.ghatFrom.ghatName} to ${trip.route.ghatTo.ghatName}`,
                            tripDate: trip.tripDate,
                            departureTime: trip.departureTime,
                            tripDuration: trip.tripDuration,
                            dockNo: trip.dockNo,
                            bookingMode: trip.bookingMode,
                            serviceType: trip.serviceType,
                            vessel: trip.vessel.name,
                            onlineSeat: trip.onlineSeat,
                            offlineSeat: trip.offlineSeat,
                            status: trip.tripStatus,
                            deckItems: trip.tripDeckItems
                        })
                    });

                    setTripTableRows(rows);
                    setTripList(trips);
                }
            }
        } catch (error) {
            handleError(error);
        }
    }

    // ==============================================================
    // -------------------- SAVE TRIP SETTINGS  ---------------------
    // ==============================================================
    const saveTrip = async (
        postData: TripPost
    ) => {
        try {
            const response: AxiosResponse = await postAuthRequest(
                apiUrl.manage.trip.updateTrip,
                postData
            );
            const { message } = response.data;
            toast.success(message);
            return true;
        } catch (error) {
            handleError(error);
            return false;
        }
    }

    return {
        handleInputChange,
        generateGhatFromOptions,
        generateGhatToOptions,
        fetchTrips,
        saveTrip
    }
}
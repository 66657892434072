import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Body from '../../../components/body/Body';
import DefaultButton from '../../../components/buttons/DefaultButton';
import Card from '../../../components/cards/Card';
import FormControl from '../../../components/forms/FormControl';
import { SelectOption } from '../../../constants/type-constants';
import { useCommonService } from '../../../hooks/useCommonService';
import { useVesselLayoutService } from './useVesselLayoutService';
import { VesselLayoutSlot } from './vessel-layout-interfaces';
import VesselLayoutSlots from './VesselLayoutSlots';

const VesselLayout: React.FC = () => {

    const location = useLocation();
    const { vessel, divisionId } = location.state || {};

    const {
        fetchDeckOptions
    } = useCommonService();

    const {
        handleBackButtonClick,
        handleNumberInputChange,
        handleInputChange,
        handleGenerateLayoutButtonClick,
        fetchVesselLayout
    } = useVesselLayoutService();

    const [deckOptions, setDeckOptions] = useState<SelectOption[]>([]);

    const [rows, setRows] = useState(0);
    const [columns, setColumns] = useState(0);
    const [deck, setDeck] = useState('');

    const [rowInput, setRowInput] = useState(0);
    const [colInput, setColInput] = useState(0);
    const [deckInput, setDeckInput] = useState('');

    const [vesselLayoutSlots, setVesselLayoutSlots] = useState<VesselLayoutSlot[]>([]);

    useEffect(() => {
        fetchDeckOptions(setDeckOptions, setDeckInput);
    }, []);

    useEffect(() => {
        if (deckInput && deckInput != '') {
            fetchVesselLayout(
                vessel.id,
                deckInput,
                setRows,
                setRowInput,
                setColumns,
                setColInput,
                setVesselLayoutSlots
            );
            setDeck(deckInput);
        }
    }, [deckInput]);

    return (
        <Body>
            <div className='flex items-center justify-between'>
                <h2>Vessel Matrix - Seat Layout</h2>
                <div className='cursor-pointer font-medium' onClick={() => handleBackButtonClick(divisionId)}>
                    Back
                </div>
            </div>

            <div className='mb-4'>
                <Card>
                    <h4>Vessel: {vessel.name} | Reg. No: {vessel.regNo}</h4>
                    <div className='grid grid-cols-4 gap-4 items-end'>
                        <FormControl
                            placeholder='Rows'
                            label='Rows'
                            type={'number'}
                            id={'rows'}
                            value={rowInput}
                            onChange={handleNumberInputChange(setRowInput)}
                        />
                        <FormControl
                            placeholder='Columns'
                            label='Columns'
                            type={'number'}
                            id={'columns'}
                            value={colInput}
                            onChange={handleNumberInputChange(setColInput)}
                        />
                        <FormControl
                            placeholder='Deck No'
                            label='Select Deck'
                            type={'select'}
                            id={'deckNo'}
                            value={deckInput}
                            options={deckOptions}
                            onChange={handleInputChange(setDeckInput)}
                        />
                        <div>
                            <DefaultButton
                                onClick={() => handleGenerateLayoutButtonClick(
                                    rowInput,
                                    colInput,
                                    deckInput,
                                    setRows,
                                    setColumns,
                                    setDeck
                                )}
                                buttonText={'Generate Layout'}
                                disabledTime={1}
                            />
                        </div>
                    </div>
                </Card>
            </div>

            <VesselLayoutSlots
                vessel={vessel}
                rows={rows}
                columns={columns}
                deck={deck}
                vesselLayoutSlots={vesselLayoutSlots}
                setVesselLayoutSlots={setVesselLayoutSlots}
            />
        </Body>
    )
}

export default VesselLayout
import { SetStateAction } from "react";
import { useCommonService } from "../../../hooks/useCommonService"
import { SelectOption } from "../../../constants/type-constants";

export const useTicketBookingService = () => {

    const {
        fetchGenders,
        fetchPwd
    } = useCommonService();

    const fetchGenderOptions = async (
        updateState: React.Dispatch<SetStateAction<SelectOption[]>>,
        setOption: React.Dispatch<SetStateAction<string>>
    ) => {
        const options: SelectOption[] = await fetchGenders();
        if (options) {
            updateState(options);
            setOption(options[0]?.value);
        }
    }

    const fetchPwdOptions = async (
        updateState: React.Dispatch<SetStateAction<SelectOption[]>>,
        setOption: React.Dispatch<SetStateAction<string>>
    ) => {
        const options: SelectOption[] = await fetchPwd();
        if (options) {
            updateState(options);
            setOption(options[0]?.value);
        }
    }

    return {
        fetchGenderOptions,
        fetchPwdOptions
    }
}
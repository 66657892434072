const updatePriceDetails = (deckItems: any, passengerList: any, vehicleList: any, goodsList: any) => {
    const passengerTicketList: any = [];
    const vehicleTicketList: any = [];
    const goodsTicketList: any = [];
    const conveniencePercentage = 3;
    let netAmount = 0;
    let convenienceFee = 0;
    let totalPrice = 0;

    const insertTicket = (category: string, deckItem: any, itemIndex: any) => {
        const updateExisting = {
            itemType: deckItem?.item?.itemType || '',
            itemDetails: `${deckItem?.item?.itemCategory} (${deckItem?.item?.itemType})`,
            itemQuantity: Number(passengerTicketList[itemIndex]?.itemQuantity) + 1,
            itemPrice: Number(deckItem?.rate).toFixed(2),
            itemTotalPrice: (Number(passengerTicketList[itemIndex]?.itemPrice) + Number(deckItem?.rate)).toFixed(2),
        }
        const addNew = {
            itemType: deckItem?.item?.itemType || '',
            itemDetails: `${deckItem?.item?.itemCategory} (${deckItem?.item?.itemType})`,
            itemQuantity: 1,
            itemPrice: Number(deckItem?.rate).toFixed(2),
            itemTotalPrice: Number(deckItem?.rate).toFixed(2),
        }

        netAmount = netAmount + Number(deckItem.rate);
        convenienceFee = convenienceFee + ((Number(deckItem.rate) / 100) * conveniencePercentage);

        if (category == 'Passenger') {
            if (itemIndex != -1) {
                passengerTicketList[itemIndex] = updateExisting;
            } else {
                passengerTicketList.push(addNew);
            }
        }
        if (category == 'Vehicle') {
            if (itemIndex != -1) {
                vehicleTicketList[itemIndex] = updateExisting;
            } else {
                vehicleTicketList.push(addNew);
            }
        }
        if (category == 'Goods') {
            const goodsDetails = {
                itemType: deckItem.item?.itemType,
                itemDetails: `${deckItem.item?.itemCategory} (${itemIndex} qls)`,
                itemQuantity: 1,
                itemPrice: `${Number(deckItem.rate).toFixed(2)}/ql`, // Index is used for receiving weight
                itemTotalPrice: (Number(deckItem.rate) * Number(itemIndex)).toFixed(2),
            }
            vehicleTicketList.push(goodsDetails);
        }
    }

    if (passengerList.length > 0) {
        passengerList.forEach((passenger: any) => {
            if (passenger.pwd == 'Yes') {
                const deckItem = deckItems.find((deckItem: any) => deckItem.item?.itemType == 'PWD');
                const itemIndex = passengerTicketList.findIndex((item: any) => item.itemType == deckItem.item?.itemType);
                insertTicket('Passenger', deckItem, itemIndex);
            } else if (Number(passenger.age) >= 60) {
                const deckItem = deckItems.find((deckItem: any) => deckItem.item?.itemType == 'Senior Citizen');
                const itemIndex = passengerTicketList.findIndex((item: any) => item.itemType == deckItem.item?.itemType);
                insertTicket('Passenger', deckItem, itemIndex);
            } else if (passenger.gender == 'Female') {
                const deckItem = deckItems.find((deckItem: any) => deckItem.item?.itemType == 'Female');
                const itemIndex = passengerTicketList.findIndex((item: any) => item.itemType == deckItem.item?.itemType);
                insertTicket('Passenger', deckItem, itemIndex);
            } else {
                const deckItem = deckItems.find((deckItem: any) => deckItem.item?.itemType == 'Unreserved');
                const itemIndex = passengerTicketList.findIndex((item: any) => item.itemType == deckItem.item?.itemType);
                insertTicket('Passenger', deckItem, itemIndex);
            }
        })
    }

    if (vehicleList.length > 0) {
        vehicleList.forEach((vehicle: any) => {
            if (vehicle.vehicleType == 'Bicycle') {
                const deckItem = deckItems.find((deckItem: any) => deckItem.item?.itemType == 'Bicycle');
                const itemIndex = vehicleTicketList.findIndex((item: any) => item.itemType == deckItem.item?.itemType);
                insertTicket('Vehicle', deckItem, itemIndex);
            } else if (vehicle.vehicleType == 'Two Wheeler') {
                const deckItem = deckItems.find((deckItem: any) => deckItem.item?.itemType == 'Two Wheeler');
                const itemIndex = vehicleTicketList.findIndex((item: any) => item.itemType == deckItem.item?.itemType);
                insertTicket('Vehicle', deckItem, itemIndex);
            } else if (vehicle.vehicleType == 'Four Wheeler') {
                const deckItem = deckItems.find((deckItem: any) => deckItem.item?.itemType == 'Four Wheeler');
                const itemIndex = vehicleTicketList.findIndex((item: any) => item.itemType == deckItem.item?.itemType);
                insertTicket('Vehicle', deckItem, itemIndex);
            }
        })
    }
    
    if (goodsList.length > 0) {
        goodsList.forEach((goods: any) => {
            const deckItem = deckItems.find((deckItem: any) => deckItem.item?.itemType == 'Goods');
            insertTicket('Goods', deckItem, goods.weight);
        })
    }

    totalPrice = netAmount + convenienceFee;

    return {
        tickets: [...passengerTicketList, ...vehicleTicketList, ...goodsTicketList],
        price: {
            netAmount: netAmount.toFixed(2),
            convenienceFee: convenienceFee.toFixed(2),
            totalPrice: totalPrice.toFixed(2)
        }
    };
}

export const ticketBookingService = {
    updatePriceDetails
}
import React, { useEffect, useState } from 'react'
import DefaultButton from '../../../components/buttons/DefaultButton'
import Card from '../../../components/cards/Card'
import ModalWrapper from '../../../components/modals/ModalWrapper'
import { SelectOption } from '../../../constants/type-constants'
import { useCommonService } from '../../../hooks/useCommonService'
import VesselBg from '../../../resources/images/vessel/boat_bg.png'
import Female from '../../../resources/images/vessel/female.svg'
import FourWheeler from '../../../resources/images/vessel/four_wheeler.svg'
import TwoWheeler from '../../../resources/images/vessel/two_wheeler.svg'
import Unreserved from '../../../resources/images/vessel/unreserved.svg'
import { Vessel } from '../vessel-management/vessel-interfaces'
import { useVesselLayoutService } from './useVesselLayoutService'
import { VesselLayoutSlot } from './vessel-layout-interfaces'

interface VesselLayoutSlotsProps {
    vessel: Vessel
    rows: number
    columns: number
    deck: string
    vesselLayoutSlots: VesselLayoutSlot[]
    setVesselLayoutSlots: React.Dispatch<React.SetStateAction<VesselLayoutSlot[]>>
}

const getSeatIcon = (seatType: string) => {
    switch (seatType) {
        case 'Unreserved':
            return (<img src={Unreserved} alt="Unreserved" className='flex w-6 h-6' />);
        case 'Female':
            return (<img src={Female} alt="Female" className='flex w-6 h-6' />);
        case 'Two Wheeler':
            return (<img src={TwoWheeler} alt="Two Wheeler" className='flex w-8' />);
        case 'Four Wheeler':
            return (<img src={FourWheeler} alt="Four Wheeler" className='flex w-8' />);
        default:
            return (<img src={Unreserved} alt="Seat" className='flex w-6 h-6' />);
    }
}

const VesselLayoutSlots: React.FC<VesselLayoutSlotsProps> = ({ vessel, rows, columns, deck, vesselLayoutSlots, setVesselLayoutSlots }) => {

    const {
        fetchItemOptions
    } = useCommonService();

    const {
        getItemBorderClass,
        handleLayoutInputChange,
        handleSlotItemChange,
        saveVesselLayout
    } = useVesselLayoutService();

    const [itemOptions, setItemOptions] = useState<SelectOption[]>([]);

    const [seatPlanModalOpen, setSeatPlanModalOpen] = useState(false);
    const handleSeatPlanModalOpen = () => setSeatPlanModalOpen(true);
    const handleSeatPlanModalClose = () => setSeatPlanModalOpen(false);

    useEffect(() => {
        fetchItemOptions(setItemOptions);
    }, []);

    const handleLayoutPreview = async () => {
        handleSeatPlanModalOpen();
    }

    const generateLayoutPreview = () => {
        const divs = [];
        for (let i = 0; i < rows; i++) {
            const row = [];
            for (let j = 0; j < columns; j++) {
                const seat = vesselLayoutSlots.find(slot => slot.rowNo == i && slot.columnNo == j);
                let seatDiv;
                if (seat && seat.seatNo != '') {
                    seatDiv =
                        <div key={`seatIcon_${i}_${j}`}>
                            {getSeatIcon(seat.item.itemName)}
                        </div>
                } else {
                    seatDiv = <div className='flex w-6 h-6'></div>
                }
                row.push(
                    <div key={`layout_${i}_${j}`}>
                        {seatDiv}
                    </div>
                );
            }
            divs.push(
                <div key={`previewRow_${i}`} className='flex justify-center'>{row}</div>
            )
        }
        return (
            <>
                {divs}
            </>
        )
    }

    const handleLayoutSave = async () => {
        const postData = {
            vessel: {
                id: vessel.id
            },
            deck: {
                id: deck
            },
            vesselLayoutSlots: vesselLayoutSlots.filter(slot => slot.seatNo.trim() !== '' && slot.rowNo < rows && slot.columnNo < columns)
        }
        saveVesselLayout(postData);
    }


    const newMatrix = [];

    if (rows > 0 && columns > 0) {
        for (let i = 0; i < rows; i++) {
            const row = [];
            for (let j = 0; j < columns; j++) {
                const slot = vesselLayoutSlots.find(slot => slot.rowNo === i && slot.columnNo === j);
                const borderColor = slot?.borderColor || getItemBorderClass('default');
                row.push(
                    <div className={`border rounded ${borderColor}`} key={`cell_${i}_${j}`}>
                        <div>
                            <input
                                type='text'
                                id={`cell_${i}_${j}`}
                                name={`cell_${i}_${j}`}
                                className={`h-8 border rounded-t text-center px-2 w-20 ${borderColor}`}
                                onChange={handleLayoutInputChange(
                                    itemOptions,
                                    vesselLayoutSlots,
                                    setVesselLayoutSlots
                                )}
                                value={slot?.seatNo || ''}
                            />
                        </div>
                        <div className='text-xs'>
                            <select
                                name={`item_${i}_${j}`}
                                id={`item_${i}_${j}`}
                                className={`h-8 border rounded-b px-2 w-20 ${borderColor}`}
                                value={slot?.item.id || ''}
                                onChange={handleSlotItemChange(
                                    itemOptions,
                                    vesselLayoutSlots,
                                    setVesselLayoutSlots
                                )}>
                                {itemOptions.map(item => (
                                    <option key={item.value} value={item.value}>{item.label}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                );
            }

            newMatrix.push(
                <div className='flex gap-2 my-2' key={`row_${i}`}>
                    {row}
                </div>
            );
        }
    }

    return (
        <>
            <Card>
                <div className='mb-4'>
                    <div className='overflow-x-scroll'>
                        {newMatrix}
                    </div>
                </div>
                <div className='mb-4'>
                    <DefaultButton
                        onClick={handleLayoutPreview}
                        buttonText={'Preview'}
                        disabledTime={1}
                    />
                </div>

            </Card>
            <ModalWrapper
                title={'Seat Plan Preview'}
                open={seatPlanModalOpen}
                handleClose={handleSeatPlanModalClose}>
                <div className='relative'>
                    <div className='flex flex-col w-max p-8 mx-auto' style={{
                        backgroundImage: `url(${VesselBg})`,
                        backgroundSize: '100% 100%',
                    }}>
                        {generateLayoutPreview()}
                    </div>
                </div>
                <div className='my-4'>
                    <DefaultButton
                        onClick={handleLayoutSave}
                        buttonText={'Save'}
                        disabledTime={1}
                    />
                </div>
            </ModalWrapper>
        </>
    );
}

export default VesselLayoutSlots
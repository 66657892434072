import React, { useEffect, useState } from 'react'
import Body from '../../../components/body/Body'
import BicycleIcon from '../../../resources/icons/bicycle-icon.svg';
import BikeIcon from '../../../resources/icons/bike-icon.svg';
import CarIcon from '../../../resources/icons/car-icon.svg';
import LuggageIcon from '../../../resources/icons/luggage-icon.svg';
import SeatIcon from '../../../resources/icons/seat-icon.svg';
import Card from '../../../components/cards/Card'
import TicketTypeClickable from '../../../components/clickables/TicketTypeClickable';
import { dateUtil } from '../../../utility/dateUtil';
import { Goods, ModalAction, Passenger, Vehicle } from './typeDefs';
import TicketDetails from './TicketDetails';
import PassengerDetails from './item-ticket/PassengerDetails';
import VehicleDetails from './item-ticket/VehicleDetails';
import GoodsDetails from './item-ticket/GoodsDetails';
import { ticketBookingService } from './booking-summary/bookingSummaryService';

const TicketBooking: React.FC = () => {

    const [itemCapacities, setItemCapacities] = useState<any>('');
    const [bookingSummary, setBookingSummary] = useState<any[]>([]);
    const [price, setPrice] = useState<any>({});
    
    const [passengerList, setPassengerList] = useState<Passenger[]>(() => {
        const storedPassengerList = localStorage.getItem('passengerList');
        if (storedPassengerList) {
            return JSON.parse(storedPassengerList);
        }
        return [];
    });
    const [vehicleList, setVehicleList] = useState<Vehicle[]>(() => {
        const storedVehicleList = localStorage.getItem('vehicleList');

        if (storedVehicleList) {
            return JSON.parse(storedVehicleList);
        }
        return [];
    });
    const [goodsList, setGoodsList] = useState<Goods[]>(() => {
        const storedGoodsList = localStorage.getItem('goodsList');
        if (storedGoodsList) {
            return JSON.parse(storedGoodsList);
        }
        return [];
    });

    useEffect(() => {
        if (passengerList.length == 0 && vehicleList.length == 0 && goodsList.length == 0) {
            setBookingSummary([])
        } else {
            updatePriceDetails();
        }
        localStorage.setItem('passengerList', JSON.stringify(passengerList));
        localStorage.setItem('vehicleList', JSON.stringify(vehicleList));
        localStorage.setItem('goodsList', JSON.stringify(goodsList));
    }, [passengerList, vehicleList, goodsList]);

    const [passengerModalCallAction, setPassengerModalCallAction] = useState<ModalAction>({ action: '' });
    const [vehicleModalCallAction, setVehicleModalCallAction] = useState<ModalAction>({ action: '' });
    const [goodsModalCallAction, setGoodsModalCallAction] = useState<ModalAction>({ action: '' });

    const [trip, setTrip] = useState<any>(() => {
        const selectedTrip: any = localStorage.getItem('selectedTrip');
        if (selectedTrip) {
            const trip = JSON.parse(selectedTrip)
            return trip;
        } else {
            return {};
        }
    });

    const handlePasssengerAddClick = () => {
        setPassengerModalCallAction({ action: 'add' });
    }

    const handleVehicleAddClick = (vehicleType: string) => {
        setVehicleModalCallAction({
            action: 'add',
            vehicleType: vehicleType
        })
    }

    const handleGoodsAddClick = () => {
        setGoodsModalCallAction({ action: 'add' });
    }

    const ticketButtons = [
        { clickHandler: handlePasssengerAddClick, icon: SeatIcon, availability: itemCapacities?.seat, hoverText: 'Book Seat', unitLabel: 'Seats' },
        { clickHandler: () => handleVehicleAddClick('Bicycle'), icon: BicycleIcon, availability: itemCapacities?.bicycleCapacity, hoverText: 'Book Slot', unitLabel: 'Slots' },
        { clickHandler: () => handleVehicleAddClick('Two Wheeler'), icon: BikeIcon, availability: itemCapacities?.twoWheelerCapacity, hoverText: 'Book Slot', unitLabel: 'Slots' },
        { clickHandler: () => handleVehicleAddClick('Four Wheeler'), icon: CarIcon, availability: itemCapacities?.fourWheelerCapacity, hoverText: 'Book Slot', unitLabel: 'Slots' },
        { clickHandler: handleGoodsAddClick, icon: LuggageIcon, availability: itemCapacities?.goodsCapacity, hoverText: 'Book Slot', unitLabel: 'Qls' },
    ]


    const updatePriceDetails = () => {
        const { tickets, price } = ticketBookingService.updatePriceDetails(trip.tripDeckItems, passengerList, vehicleList, goodsList);
        setBookingSummary(tickets);
        setPrice(price);
    }

    return (
        <Body>
            <>
                <h1>Ticket Booking</h1>
                <Card>
                    <div className='flex flex-wrap items-center -mt-2'>
                        <div className='w-full lg:w-4/6 lg:pr-2'>
                            <div className='w-full'>
                                <h3 className='m-0 text-sky-500'>
                                    {trip.vessel?.name}
                                </h3>
                            </div>
                            <div className='mb-1'>
                                Book Tickets
                            </div>
                            <div className="flex mb-3 flex-wrap">
                                {
                                    ticketButtons.map((button, index) => (
                                        <div className="w-1/2 lg:w-1/5" key={index}>
                                            <button className='w-full'
                                                onClick={button.clickHandler}
                                            >
                                                <TicketTypeClickable
                                                    icon={button.icon}
                                                    availability={button.availability}
                                                    hoverText={button.hoverText}
                                                    unitLabel={button.unitLabel}
                                                />
                                            </button>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        {/* ---------- TRIP DETAILS ---------- */}
                        <div className='w-full lg:w-2/6 lg:px-2 lg:ps-10 h-full'>
                            <div className="flex flex-col justify-between h-full">
                                <h4 className='m-0 mb-2 text-sky-500'>
                                    Trip Details
                                </h4>
                                <div>
                                    <div className="flex">
                                        <div className='my-1 w-1/2 px-2'>
                                            <div className='text-sm text-neutral-500 font-semibold'>Date</div>
                                            <div className='font-semibold text-neutral-600'>{trip && dateUtil.formatDateWithDay(trip?.tripDate)}</div>
                                        </div>
                                        <div className='my-1 w-1/2 px-2'>
                                            <div className='text-sm text-neutral-500 font-semibold'>Departure</div>
                                            <div className='font-semibold text-neutral-600'>{trip && dateUtil.formatTime(trip?.departureTime)}</div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="flex">
                                        <div className='my-1 w-1/2 px-2'>
                                            <div className='text-sm text-neutral-500 font-semibold'>Source</div>
                                            <div className='font-semibold text-neutral-600'>{trip?.route?.ghatFrom.ghatName}</div>
                                        </div>
                                        <div className='my-1 w-1/2 px-2'>
                                            <div className='text-sm text-neutral-500 font-semibold'>Destination</div>
                                            <div className='font-semibold text-neutral-600'>{trip?.route?.ghatTo.ghatName}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
                <TicketDetails
                    passengerList={passengerList}
                    setPassengerList={setPassengerList}
                    vehicleList={vehicleList}
                    setVehicleList={setVehicleList}
                    goodsList={goodsList}
                    setGoodsList={setGoodsList}
                    setPassengerModalCallAction={setPassengerModalCallAction}
                    setVehicleModalCallAction={setVehicleModalCallAction}
                    setGoodsModalCallAction={setGoodsModalCallAction} />
                <PassengerDetails
                    passengerList={passengerList}
                    setPassengerList={setPassengerList}
                    callAction={passengerModalCallAction}
                    setCallAction={setPassengerModalCallAction}
                />
                <VehicleDetails
                    trip={trip}
                    vehicleList={vehicleList}
                    setVehicleList={setVehicleList}
                    callAction={vehicleModalCallAction}
                    setCallAction={setVehicleModalCallAction}
                    passengerList={passengerList}
                />
                <GoodsDetails
                    goodsList={goodsList}
                    setGoodsList={setGoodsList}
                    callAction={goodsModalCallAction}
                    setCallAction={setGoodsModalCallAction}
                />
            </>
        </Body>
    )
}

export default TicketBooking
import { AxiosResponse } from "axios";
import apiUrl from "../../../constants/api-constants";
import { SelectOption } from "../../../constants/type-constants";
import { useApiService } from "../../../hooks/useApiService";
import { useCommonService } from "../../../hooks/useCommonService";
import { useHandleErrorService } from "../../../hooks/useHandleErrorService";
import { Ghat, GhatPost, GhatTableRows } from "./ghat-interfaces";
import toast from "react-hot-toast";
import Swal from "sweetalert2";
import swalConfig from "../../../config/swalConfig";

export const useGhatManagementService = () => {
    const { fetchAuthRequest, postAuthRequest } = useApiService();
    const { handleError } = useHandleErrorService();
    
    const handleInputChange = (updateState: React.Dispatch<React.SetStateAction<string>>) =>
        (event: React.ChangeEvent<HTMLSelectElement>) => {
            updateState(event.target.value);
        };

    const fetchGhatsByDivision = async (
        division: string,
        setTableRows: React.Dispatch<React.SetStateAction<GhatTableRows[]>>
    ) => {
        try {
            const response: AxiosResponse = await fetchAuthRequest(
                apiUrl.manage.ghat.getGhatsByDivision,
                {
                    divisionId: division
                }
            );

            const { object: ghats } = response.data;
            const rows: GhatTableRows[] = [];

            ghats.forEach((ghat: Ghat, index: number) => {
                rows.push({
                    slNo: index + 1,
                    id: ghat.id,
                    ghatName: ghat.ghatName,
                    divisionId: ghat.division.id,
                    divisionName: ghat.division.divisionName,
                    status: ghat.status
                })
            });

            setTableRows(rows);
        } catch (error) {
            handleError(error);
        }
    }

    const saveGhat = async (
        ghat: GhatPost,
    ) => {
        let saved = false;
        const save = async () => {
            try {
                const response: AxiosResponse = await postAuthRequest(
                    apiUrl.manage.ghat.saveGhat,
                    ghat
                );
                const { message } = response.data;
                toast.success(message);
                saved = true;
            } catch (error) {
                handleError(error);
            }
        }

        if (ghat.status == 'Deleted') {
            await Swal.fire(swalConfig({
                title: 'Are you sure you want to delete this ghat?',
                icon: 'question',
                confirmButtonText: 'Delete',
                showCancelButton: true,
                cancelButtonText: 'Cancel',
                position: 'center'
            })).then(async (result) => {
                if (result.isConfirmed) {
                    await save();
                }
            });
        } else {
            await save();
        }

        return saved;
    }

    const deleteGhat = async (ghat: Ghat) => {
        let deleted = false;
        await Swal.fire(swalConfig({
            title: 'Are you sure you want to delete this ghat?',
            icon: 'question',
            confirmButtonText: 'Delete',
            showCancelButton: true,
            cancelButtonText: 'Cancel',
            position: 'center'
        })).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response: AxiosResponse = await postAuthRequest(
                        apiUrl.manage.ghat.saveGhat,
                        ghat
                    );
                    const { message } = response.data;
                    toast.success(message);
                    deleted = true;
                } catch (error) {
                    handleError(error);
                }
            }
        });
        return deleted;
    }

    return {
        handleInputChange,
        fetchGhatsByDivision,
        saveGhat,
        deleteGhat,
    }
}
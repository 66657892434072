import React, { useEffect, useState } from 'react'
import ModalWrapper from '../../../../components/modals/ModalWrapper'
import FormControl from '../../../../components/forms/FormControl'
import DefaultButton from '../../../../components/buttons/DefaultButton'
import toast from 'react-hot-toast'
import { Goods, ModalAction } from '../typeDefs'

interface GoodsDetailsProps {
    goodsList: Goods[]
    setGoodsList: React.Dispatch<React.SetStateAction<Goods[]>>
    callAction: ModalAction
    setCallAction: React.Dispatch<React.SetStateAction<ModalAction>>
}

const GoodsDetails: React.FC<GoodsDetailsProps> = ({
    goodsList,
    setGoodsList,
    callAction,
    setCallAction
}) => {

    const [goodsId, setGoodsId] = useState('');
    const [weight, setWeight] = useState('');
    const [description, setDescription] = useState('');

    const [goodsDetailsModalOpen, setGoodsDetailsModalOpen] = useState(false);
    const handleGoodsDetailsModalOpen = () => setGoodsDetailsModalOpen(true);
    const handleGoodsDetailsModalClose = () => {
        setCallAction({
            action: '',
            id: ''
        });
        setGoodsDetailsModalOpen(false)
    };

    useEffect(() => {
        if (callAction.action == 'add') {
            setGoodsId('');
            setWeight('');
            setDescription('');
            handleGoodsDetailsModalOpen();
        }

        if (callAction.action == 'edit') {
            const goods = goodsList.find(goods => goods.id == callAction.id);
            if (goods) {
                setGoodsId(goods.id)
                setWeight(goods.weight);
                setDescription(goods.description);
                handleGoodsDetailsModalOpen();
            } else {
                toast.error('Invalid Goods Details');
            }
        }
    }, [callAction]);

    // const goods = goodsList.find(goods => goods.id == id);
    //     if (goods) {
    //         setGoodsId(goods.id)
    //         setWeight(goods.weight);
    //         setDescription(goods.description);
    //         handleGoodsDetailsModalOpen();
    //     } else {
    //         toast.error('Invalid Goods Details');
    //     }

    const handleGoodsInputChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
        const value = e.target.value;
        switch (e.target.name) {
            case 'description':
                setDescription(value);
                break;
            case 'weight':
                setWeight(value);
                break;
            default:
                break;
        }
    }

    const handleSaveGoodsDetailsClick = async () => {
        const goods = {
            id: goodsId ? goodsId : (goodsList.length + 1).toString(),
            description: description,
            weight: weight,
        }
        const goodsIndex = goodsList.findIndex(goods => goods.id == goodsId);
        if (goodsIndex != -1) {
            const updatedGoodsList = [...goodsList];
            updatedGoodsList[goodsIndex] = goods;
            setGoodsList(updatedGoodsList)
            toast.success('Goods Details Updated');
        } else {
            setGoodsList([...goodsList, goods]);
            toast.success('Goods Added');
        }
        handleGoodsDetailsModalClose();
    }

    return (
        <ModalWrapper
            open={goodsDetailsModalOpen}
            handleClose={handleGoodsDetailsModalClose}
            title={'Goods Details'} >
            <form id='goodsForm' className='flex flex-wrap w-full'>
                <div className='w-full mb-3 lg:w-1/4'>
                    <input type="text" value={goodsId} readOnly hidden />
                    <FormControl
                        label='Weight(qls)'
                        type={'number'}
                        id={'weight'}
                        value={weight}
                        onChange={handleGoodsInputChange}
                        mandatoryField={true}
                    />
                </div>
                <div className='w-full mb-3 lg:w-3/4 lg:pl-4'>
                    <FormControl
                        label='Description'
                        type={'text'}
                        id={'description'}
                        value={description}
                        onChange={handleGoodsInputChange}
                        mandatoryField={true}
                    />
                </div>
                <div className='flex justify-center w-full mt-3'>
                    <DefaultButton
                        onClick={handleSaveGoodsDetailsClick}
                        buttonText={"Save Goods Details"}
                    />
                </div>
            </form>
        </ModalWrapper >
    )
}

export default GoodsDetails
const apiUrl = {

    // BASE_URL: 'http://192.168.29.27:8080',
    // BASE_URL: 'http://13.232.123.30:8080/ETICKETING',
    BASE_URL: 'https://eticketing-api.bohniman.in',

    USER_CONSENT: `www.google.com`,

    // AUTH
    auth: {
        postSignUp: `/auth/signup`,
        postSignIn: `/auth/signin`,
        postResetPassword: `/auth/reset-password`,
        postSendResetPasswordOtp: `/auth/reset-password-otp`,
        postResendOtp: `/auth/resend-otp`,
        postRefreshToken: `/auth/refreshtoken`,
        postSignOut: `/auth/logout`,
        postSendOtp: `/auth/get-otp`,
    },

    // COMMON
    common: {
        enums: {
            pwd: `/api/common/pwd`,
            modes: `/api/common/modes`,
            owners: `/api/common/owners`,
            genders: `/api/common/genders`,
            statuses: `/api/common/statuses`,
            unitTypes: `/api/common/unit-types`,
            vesselTypes: `/api/common/vessel-types`,
            deviceTypes: `/api/common/device-types`,
            serviceTypes: `/api/common/service-types`,
            nightNavigations: `/api/common/night-navigations`,
            bookingModes: `/api/common/booking-mode`,
        },
        master: {
            getAllWeekDays: `/api/common/get-all-week-days`,
            getAllItems: `/api/common/get-all-items`,
            getAllDecks: `/api/common/get-all-decks`,
            getActiveDivisions: `/api/common/get-active-divisions`,
            getActiveGhats: `/api/common/get-active-ghats`,
            getActiveGhatsByDivision: `/api/common/get-active-ghats-by-division`,
            getActiveGhatsByDivisionList: `/api/common/get-active-ghats-by-division-list`,
            getActiveRoutes: `/api/common/get-active-routes`,
            getActiveRoutesByDivision: `/api/common/get-active-routes-by-division`,
            getActiveVesselsByDivision: `/api/common/get-active-vessels-by-division`,
        }
    },

    // MANAGEMENT
    manage: {
        division: {
            saveDivision: `/api/manage/save-division`,
            getDivisions: `/api/manage/get-divisions`,
        },
        ghat: {
            saveGhat: `/api/manage/save-ghat`,
            getGhatsByDivision: `/api/manage/get-ghats-by-division`,
            getGhats: `/api/manage/get-all-ghats`,
        },
        route: {
            saveRoute: `/api/manage/save-route`,
            getRoutes: `/api/manage/get-all-routes`,
            getRoutesByDivision: `/api/manage/get-routes-by-division`,
            getRoutesByGhat: `/api/manage/get-routes-by-ghat`,
        },
        vessel: {
            saveVessel: `/api/manage/save-vessel`,
            getVesselsByGhat: `/api/manage/get-vessels-by-ghat`,
            getVesselsByDivision: `/api/manage/get-vessels-by-division`,
        },
        vesselLayout: {
            saveVesselLayout: `/api/manage/save-vessel-layout`,
            getVesselsLayout: `/api/manage/get-vessel-layout`,
        },
        trip: {
            updateTrip: `/api/manage/save-trip`,
            getTrips: `/api/manage/get-trips`,
        },
        tripSettings: {
            saveTripSettings: `/api/manage/save-trip-settings`,
            getTripSettingsByRoute: `/api/manage/get-trip-settings-by-route`,
        },
        rate: {
            saveRate: `/api/manage/save-rate`,
            getRatesByRoute: `/api/manage/get-rates-by-route`,
        },
    },

    // GUEST
    guest: {
        getRoutes: `/api/guest/get-routes`,
        getTrips: `/api/guest/get-trips`,
    },

    // BOOKING
    booking: {
        createBooking: `/api/user/create-booking`,
    },
}
export default apiUrl;
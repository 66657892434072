import React, { SetStateAction } from 'react'
import { Goods, ModalAction, Passenger, Vehicle } from './typeDefs'
import Card from '../../../components/cards/Card';
import { BorderColorRounded, CancelRounded } from '@mui/icons-material';
import Swal from 'sweetalert2';
import swalConfig from '../../../config/swalConfig';

interface TicketDetailsProps {
    passengerList: Passenger[]
    setPassengerList: React.Dispatch<SetStateAction<Passenger[]>>
    vehicleList: Vehicle[]
    setVehicleList: React.Dispatch<SetStateAction<Vehicle[]>>
    goodsList: Goods[]
    setGoodsList: React.Dispatch<SetStateAction<Goods[]>>
    setPassengerModalCallAction: React.Dispatch<SetStateAction<ModalAction>>
    setVehicleModalCallAction: React.Dispatch<SetStateAction<ModalAction>>
    setGoodsModalCallAction: React.Dispatch<SetStateAction<ModalAction>>
}

const TicketDetails: React.FC<TicketDetailsProps> = ({
    passengerList,
    setPassengerList,
    vehicleList,
    setVehicleList,
    goodsList,
    setGoodsList,
    setPassengerModalCallAction,
    setVehicleModalCallAction,
    setGoodsModalCallAction

}) => {

    const handlePassengerEditClick = (id: any) => {
        setPassengerModalCallAction({
            action: 'edit',
            id: id
        })
    }

    const handleVehicleEditClick = (id: any, item: string) => {
        setPassengerModalCallAction({
            action: 'edit',
            vehicleType: item,
            id: id
        })
    }

    const handleGoodsEditClick = (id: any) => {
        setGoodsModalCallAction({
            action: 'edit',
            id: id
        })
    }

    const handlePassengerRemoveClick = (id: any) => {
        const vehicle = vehicleList.find(vehicle => vehicle.passengerUniqueId == id);
        if (vehicle) {
            Swal.fire(swalConfig({
                title: 'The following vehicle will be deleted as it is mapped to the passenger.',
                text: `Reg No: ${vehicle.regNo}, Model: ${vehicle.model}`,
                icon: 'warning',
                confirmButtonText: 'Continue',
                showCancelButton: true,
                cancelButtonText: 'Cancel',
            })).then(async (result) => {
                if (result.isConfirmed) {
                    const filteredList = passengerList.filter(passenger => passenger.id != id);
                    setPassengerList(filteredList);
                }
            });
        } else {
            const filteredList = passengerList.filter(passenger => passenger.id != id);
            setPassengerList(filteredList);
        }
    }

    const handleVehicleRemoveClick = (id: any) => {
        const filteredList = vehicleList.filter(vehicle => vehicle.id != id);
        setVehicleList(filteredList);
    }

    const handleGoodsRemoveClick = (id: any) => {
        const filteredList = goodsList.filter(goods => goods.id != id);
        setGoodsList(filteredList);
    }

    const generateTicketDetailsTable = (cols: any, rows: any) => {
        return (
            <div className='overflow-x-scroll md:overflow-x-auto'>
                <table className='w-full'>
                    <thead>
                        <tr className='bg-sky-50'>
                            <th></th>
                            {cols.map((col: string, index: number) => (
                                <th key={index} className='text-start px-2 py-2 font-medium'>{col}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {rows?.map((row: any, index: number) => (
                            <tr key={index}>
                                <td className='px-2 py-2 w-10'>
                                    <div className='flex h-full gap-4 items-center'>
                                        <button className='text-sky-500' onClick={row.handleEditClick}>
                                            <BorderColorRounded />
                                        </button>
                                        <button className='text-red-400' onClick={row.handleRemoveClick}>
                                            <CancelRounded />
                                        </button>
                                    </div>
                                </td>
                                {row.fields.map((field: any) => (
                                    <td className='px-2 py-2' key={field.name}>{field.value}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }


    return (
        (passengerList.length > 0 || vehicleList.length > 0 || goodsList.length > 0) &&

        <div className='w-full flex flex-wrap'>
            <div className="w-full lg:w-8/12 lg:pr-2 h-full mt-4">
                <h4 className='mb-2'>Ticket Details</h4>
                <Card addClass={["h-full"]}>
                    {
                        passengerList.length > 0 &&
                        <div>
                            <div className='mb-1 font-semibold'>Passenger Details:</div>
                            {generateTicketDetailsTable(
                                ['Name', 'Age', 'Gender'],
                                passengerList.map(passenger => ({
                                    handleEditClick: () => handlePassengerEditClick(passenger.id),
                                    handleRemoveClick: () => handlePassengerRemoveClick(passenger.id),
                                    fields: [
                                        { name: 'passengerName', value: passenger.passengerName },
                                        { name: 'passengerAge', value: passenger.age },
                                        { name: 'passengerGender', value: passenger.gender },
                                    ]
                                }))
                            )}
                        </div>
                    }
                    {
                        vehicleList.length > 0 &&
                        <div>
                            <div className='mb-1 font-semibold'>Vehicle Details:</div>
                            {generateTicketDetailsTable(
                                ['Type', 'Reg No', 'Model', 'Owner'],
                                vehicleList.map(vehicle => ({
                                    handleEditClick: () => handleVehicleEditClick(vehicle.id, vehicle.item.itemType),
                                    handleRemoveClick: () => handleVehicleRemoveClick(vehicle.id),
                                    fields: [
                                        { name: 'vehicleType', value: vehicle.item.itemType },
                                        { name: 'regNo', value: vehicle.regNo },
                                        { name: 'model', value: vehicle.model },
                                        { name: 'ownerName', value: vehicle.ownerName },
                                    ]
                                }))
                            )}
                        </div>
                    }
                    {
                        goodsList.length > 0 &&
                        <div>
                            <div className='mb-1 font-semibold'>Vehicle Details:</div>
                            {generateTicketDetailsTable(
                                ['Description', 'Weight'],
                                goodsList.map(goods => ({
                                    handleEditClick: () => handleGoodsEditClick(goods.id),
                                    handleRemoveClick: () => handleGoodsRemoveClick(goods.id),
                                    fields: [
                                        { name: 'description', value: goods.description },
                                        { name: 'weight', value: goods.weight },
                                    ]
                                }))
                            )}
                        </div>
                    }
                </Card>
            </div>
        </div>
    )
}

export default TicketDetails
import { AxiosError } from 'axios';
import toast from 'react-hot-toast';
import { useFormErrorContext } from './useFormErrorContext';

export const useHandleErrorService = () => {
    const { formErrors, setFormErrors } = useFormErrorContext();

    const handleError = async (error: AxiosError | any) => {
        if (error.response) {
            const { data } = error.response;

            if (data.errorType === "FORM_ERR") {
                setFormErrors(data.object);
            } else if (data.errors && data.errors.length > 0) {
                const errors = data.errors;
                errors.forEach((error: string) => {
                    toast.error(error);
                });

            } else {
                toast.error(data.message || 'An error occurred');
            }
        } else if (error.request) {
            toast.error('No response received from the server');
        } else {
            console.error(error);
            toast.error('Error occurred while processing the request');
        }
    };

    return {
        handleError
    }
}
